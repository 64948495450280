import jsPDF from 'jspdf'

import countries from 'i18n-iso-countries';
import 'i18n-iso-countries/langs/en.json';


export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function exportPDF(arr) {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc?.setFontSize(15);

  const title = "Supported documents list";
  const headers = [
    [
      "Country",
      "Document type",
      "State",
      "Version",
      "Supported from",
      "Document status",
    ],
  ];

  const data = arr?.map((elt) => [
    elt.fullName,
    elt.country,
    elt.type,
    elt.state,
    elt.version,
    elt.supported,
    elt.status,
  ]);

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc?.text(title, marginLeft, 40);
  doc?.autoTable(content);
  doc?.save("Supported documents list.pdf");
}


export function sortByKey(array, key) {
  return array?.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function prepareDocStatus(pages) {
  let supportedPages;
  switch (pages) {
    case "Undefined":
      supportedPages = "Undefined";
      break;
    case "Supported":
      supportedPages = "Supported";
      break;
    case "Unsupported":
      supportedPages = "Unsupported";
      break;
    case "OnlyFrontSupported":
      supportedPages = "OnlyFrontSupported";
      break;
    case "OnlyBackSupported":
      supportedPages = "OnlyBackSupported";
      break;
    default:
      supportedPages = "Undefined";
      break;
  }
  return supportedPages;
};

export const createErrorElement = (error, methodName) => {
  return (
    <div style={{ marginLeft: "25px" }}>
      <p>{error?.response?.data.title || 'Unknown error data'}
        <br />
        {error?.message || 'No error message available. '}
        <span>. </span>
        {error?.response?.statusText || 'No status text'}
        <br />
        Error in method: <strong>{methodName}.</strong>
        <br />
        Reload page.
      </p>
    </div>
  );
};


countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// Function to convert ISO3 code to full country name
export const getCountryName = (isoCode) => {
  const country = isoCode == "D" ? "DEU" : isoCode
  return countries.getName(country, 'en');
};

export const getIsoCode = (countryNames) => {
  if (!Array.isArray(countryNames)) {
    return countryNames;
  }

  const isoCodes = countryNames.map((countryName) => {
    const isoCode = countries.getAlpha3Code(countryName, 'en');
    return isoCode || countryName;
  });

  return isoCodes;
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.toLocaleDateString('en-GB')} ${date.toLocaleTimeString('en-GB')}`; // 'en-GB' for dd/mm/yyyy and 24-hour time
};

export const formatOptions = (optionList) =>
  optionList?.filter(option => option !== '' && option != null)
      .map(option => ({ value: option, label: option }));
