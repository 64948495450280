import React from "react";

export const ForgeryList = (props) => {
  const { item, uniqueKey } = props;

  return (
    <li key={uniqueKey}>
      {item.type} :: {item.subtype}
    </li>
  );
};

export default ForgeryList;
