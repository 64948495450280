import React from "react";
import { Card, Icon } from "semantic-ui-react";
import { Empty, Skeleton } from "antd"; // Import Ant Design Skeleton
import Flag from "react-world-flags";
import { Link } from "react-router-dom";
import "./CustomCard.css";
import { getCountryName } from "../../utils/Utils";

export const CustomCard = ({ documents, isLoading }) => {

  const handleClick = () => {
    const currentUrl = window.location.href;
    localStorage.setItem("backUrl", currentUrl);
  };

  // Render Skeleton placeholders when loading
  const renderSkeletons = () => (
    <Card.Group itemsPerRow={5} stackable doubling>
      {Array.from({ length: 10 }).map((_, index) => (
        <Card key={index} className="fluid">
          <Card.Content>
            <Skeleton active title={{ width: "60%" }} paragraph={{ rows: 2 }} />
          </Card.Content>
          <Skeleton.Image style={{ width: "100%", height: "150px" }} />
        </Card>
      ))}
    </Card.Group>
  );

  return (
    <div className="card-container" id="animation">
      {isLoading ? (
        renderSkeletons()
      ) : documents?.length ? (
        <Card.Group itemsPerRow={5} stackable doubling>
          {documents.map((document) => (
            <Card
              onClick={handleClick}
              key={document.id}
              className="fluid"
            >
              <Link to={`/detail?id=${document.id}`} target="_blank">
                <Card.Content>
                  <Flag
                    className="flag"
                    code={document.country !== "D" ? document.country : "DEU"}
                    height="30"
                    width="45"
                  />
                  <Card.Header className="title">
                    {prepareTitleDoc(document)}
                  </Card.Header>

                  <Card.Meta>
                    <Icon name="calendar" />
                    <span>Published date {document.yearOfIssue == null
                      ? "N/A" : document.yearOfIssue}</span>
                  </Card.Meta>

                  <Card.Meta>
                    <Icon name="language" />
                    <span>
                      Language{" "}
                      {document.localLanguage === "" || document.localLanguage == null
                        ? "N/A"
                        : document?.localLanguage?.charAt(0).toUpperCase() + document?.localLanguage?.slice(1)}
                    </span>
                  </Card.Meta>

                  <Card.Meta>
                    <Icon name="calendar check outline" />
                    <span>From version {document.supportedFrom == null || document.supportedFrom === "Undefined"
                      ? "N/A" : document.supportedFrom}</span>
                  </Card.Meta>
                </Card.Content>
                {document.images?.length > 0 &&
                  document.images.some((image) => image.ImageStatus === "Approved") && (
                    <div
                      style={{
                        marginTop:
                          document.images.length > 1 && document.images.every((image) => image.ImageStatus === "Approved") ? "16px" : "6px",
                        display: "inline-flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {document.images.map(
                        (image, index) =>
                          image.ImageStatus === "Approved" && (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width:
                                  document.images.length > 1 && document.images.every((image) => image.ImageStatus === "Approved")
                                    ? "120px"
                                    : "150px",
                                height:
                                  document.images.length > 1 && document.images.every((image) => image.ImageStatus === "Approved")
                                    ? "120px"
                                    : "150px",
                              }}
                            >
                              <img
                                width="100%"
                                src={image.uri}
                                alt={`Thumbnail ${index + 1} for ${image.Id}`}
                                loading="lazy"
                                style={{
                                  display: "block",
                                  width: "100%",
                                  height: "auto",
                                  objectFit: "cover",
                                }}
                                onError={(e) => {
                                  e.target.style.display = "none";
                                  e.target.parentNode.innerHTML = `<div style="display: flex; align-items: center; justify-content: center; height: 100%;"><Spin /></div>`;
                                }}
                              />
                            </div>
                          )
                      )}
                    </div>
                  )}

              </Link>
            </Card>
          ))}
        </Card.Group>
      ) : (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Empty
            description="No results found"
          />
        </div>
      )}
    </div>
  );
};

const prepareTitleDoc = (document) => {
  const country = document.country === "D" ? "DEU" : document.country;
  const elements = [
    getCountryName(country) + (" (" + country + ")"),
    document.type,
    document.documentVersion,
    document.state,
  ];

  return elements
    ?.filter((element) => typeof element === "string" && element !== "")
    .join(" | ");
};

export default CustomCard;
