import React, { useEffect, useState } from 'react';
import { CommonAdminUrl } from '../../services/ServiceEndpoints';
import axios from 'axios';
import { Table, Skeleton } from 'antd';
import { formatDate } from '../../utils/Utils';
import BackButton from '../Elements/Back';
import { useUserContext } from '../../utils/UserProvider';
import { userGroupsPostFix } from '../../utils/Commons';

const Audit = ({ accessToken }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userName, userGroups } = useUserContext();
  const [isModerator, setIsModerator] = useState();

  useEffect(() => {
    const isModerator = userGroups?.some(group => group.includes(userGroupsPostFix.Moderator));
    setIsModerator(isModerator);
  }, [userGroups, userName]);

  useEffect(() => {
    const fetchAuditData = async () => {
      setLoading(true);
      try {
        const url = `${CommonAdminUrl.GetAuditData}`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const groupedData = response.data.reduce((acc, x) => {
          const statuses = JSON.parse(x.Status).map((status) => ({
            TypeDocument:
              status.TypeDocument === 1
                ? 'New Document'
                : status.TypeDocument === 2
                  ? 'Deleted Document'
                  : 'N/A',
            Classification: status.Classification,
            ErrorMessage: status.ErrorMessage || 'N/A',
          }));

          const existing = acc.find((item) => item.DocumentId === x.Id);
          if (existing) {
            existing.statuses.push(...statuses);
          } else {
            acc.push({
              key: x.Id,
              DocumentId: x.Id,
              ModifiedDate: formatDate(x.ModifiedDate),
              SuccessfulUpdates: x.SuccessfulUpdates,
              FailedUpdates: x.FailedUpdates,
              statuses,
            });
          }
          return acc;
        }, []);

        setData(groupedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after data fetch
      }
    };

    fetchAuditData();
  }, [accessToken]);

  const columns = [
    {
      title: 'Modified Date',
      dataIndex: 'ModifiedDate',
      key: 'ModifiedDate',
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      title: 'Document ID',
      dataIndex: 'DocumentId',
      key: 'DocumentId',
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      title: 'Successful Updates',
      dataIndex: 'SuccessfulUpdates',
      key: 'SuccessfulUpdates',
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      title: 'Failed Updates',
      dataIndex: 'FailedUpdates',
      key: 'FailedUpdates',
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
  ];

  const expandedRowRender = (record) => (
    <Table
      size="small"
      scroll={{
        y: 400,
        x: '100%',
      }}
      columns={[
        { title: 'Status', dataIndex: 'TypeDocument', key: 'TypeDocument' },
        { title: 'Classification', dataIndex: 'Classification', key: 'Classification' },
        {
          title: 'Error Message',
          dataIndex: 'ErrorMessage',
          key: 'ErrorMessage',
          render: (text) => (
            <span style={{ color: text !== 'N/A' ? 'red' : 'inherit' }}>{text}</span>
          ),
        },
      ]}
      dataSource={record.statuses}
      pagination={false}
      rowKey={(status) => `${record.DocumentId}-${status.TypeDocument}`}
    />
  );

  if (!isModerator) {
    return <div>No Access</div>;
  }

  return (
    <div style={{ height: 'calc(100vh - 64px)' }}>
      <BackButton />
      <div
        style={{
          textAlign: 'left',
          paddingLeft: '20px',
          paddingBottom: '20px',
          paddingTop: '10px',
          borderBottom: '2px solid #e0e0e0',
        }}
      >
        <h4 style={{ fontSize: '24px', color: '#4a90e2', margin: 0 }}>Data Transfer Audit</h4>
        <p style={{ fontSize: '14px', color: '#757575', marginTop: '10px' }}>
          This component displays document modifications. Expand each row to view additional
          details, including document status and any errors.
        </p>
      </div>

      <div style={{ paddingLeft: '2%', paddingRight: '2%', paddingBottom: '2%' }}>
        {loading ? (
          <div style={{ marginTop: "25px" }}>
            <Skeleton active paragraph={{ rows: 1 }} />
            <Skeleton active paragraph={{ rows: 1 }} />
            <Skeleton active paragraph={{ rows: 1 }} />
          </div>
        ) : (
          <Table
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 10 }}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => record.statuses?.length > 0,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Audit;
