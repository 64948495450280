export const dictionaryCountries = {
    D: "DEU",
    RKS: "Kosovo",
    PSE: "Palestine",
    ZIM : "Zimbabwe",
    UNO : "United National Organization",
    UNK: "UNK"
  };


  export const userGroupsPostFix = {
    Moderator: "Moderator",
    Contributor: "Contributor",
    Au10tix: "au10tix",    
    Viewer: "Viewer",
  };

  export const imageStatus = {
    Pending: "Pending",
    Approved: "Approved",
    Declined: "Declined",
  };
