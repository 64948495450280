import React, { createContext, useState, useContext } from "react";

const ReloadContext = createContext();

export const ReloadProvider = ({ children }) => {
  const [reloadKey, setReloadKey] = useState(0);

  const triggerReload = () => {
    setReloadKey((prevKey) => prevKey + 1);
  };

  return (
    <ReloadContext.Provider value={{ reloadKey, triggerReload }}>
      {children}
    </ReloadContext.Provider>
  );
};

export const useReload = () => useContext(ReloadContext);
