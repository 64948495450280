import { Select } from "antd";
import React, { useState } from 'react';

const FilterSelect = ({ options, placeholder, value, onChange }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleSelectChange = (selectedValues) => {
        onChange(selectedValues);
        setDropdownVisible(false);
    };

    const handleDropdownVisibleChange = (open) => {
        setDropdownVisible(open);
    };
    

    const customFilter = (input, option) => {
        const label = option.label || option.value;
        return label.toLowerCase().includes(input.toLowerCase());
    };

    return (
        <div style={{ margin: 16 }}>
            <Select
                mode="multiple"
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder={placeholder}
                optionFilterProp="label"
                value={value}
                options={options}
                onChange={handleSelectChange}
                open={dropdownVisible}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                filterOption={customFilter}
            />
        </div>
    );
};

export default FilterSelect;
