import React, { useEffect, useState } from 'react';
import ModeratePanel from './ModeratePanel';
import { userGroupsPostFix } from '../../utils/Commons';
import { useUserContext } from '../../utils/UserProvider';

const AdminComponent = ({ accessToken }) => {
    const { userName, userGroups } = useUserContext();
    const [isModerator, setIsModerator] = useState();

    // const isContributor = groups.some(group => group.includes(userGroups.Contributor));
    useEffect(() => {
        const isModerator = userGroups?.some(group => group.includes(userGroupsPostFix.Moderator));
        setIsModerator(isModerator);
    }, [userGroups, userName]);

    if (isModerator) {
        return <ModeratePanel accessToken={accessToken} userName={userName} />;
    }

    return <div>No Access</div>;
};

export default AdminComponent;
