import React, { createContext, useContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const { oktaAuth } = useOktaAuth();
    const [userName, setUserName] = useState("");
    const [userGroups, setUserGroups] = useState([]);

    useEffect(() => {
           const fetchUserGroupsFromToken = async () => {
               try {
                   // Retrieve the ID token using Okta SDK
                   const idToken = await oktaAuth?.tokenManager?.get('accessToken');
                   if (!idToken) {
                       console.error("ID Token is missing");
                       return;
                   }
                   const userGroups = idToken?.claims?.groups || [];
                   const userName = idToken?.claims?.firstName + " " + idToken?.claims?.lastName || "Unknown";
                   setUserName(userName);
                   setUserGroups(userGroups);
               } catch (error) {
                   console.error("Failed to retrieve or parse ID token:", error);
               }
           };
   
           fetchUserGroupsFromToken();
           // eslint-disable-next-line react-hooks/exhaustive-deps
       }, []);

    return (
        <UserContext.Provider value={{ userName, userGroups }}>
            {children}
        </UserContext.Provider>
    );
};
