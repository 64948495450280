import React, { useEffect, useState } from "react";
import { MainUrl } from "../../../services/ServiceEndpoints";
import Document from "../Document";
import axios from "axios";
import "react-awesome-slider/dist/styles.css";
import BackButton from "../../Elements/Back";
import Loading from "../../Elements/Loading";

const DetailsDocument = ({ accessToken }) => {
    const [document, setDocument] = useState(null);

    useEffect(() => {
        const fetchDocumentData = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const id = urlParams.get('id');
                if (!id) {
                    console.error('Document ID is missing from the URL');
                    return;
                }
    
                const documentResponse = await axios.get(
                    `${MainUrl.GetDocumentDataById}?id=${id}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                        },
                    }
                );
                const documentData = documentResponse.data;
    
                const approvedImages = documentData.images.filter(x => x.imageStatus === 'Approved');
    
                documentData.images = approvedImages;
    
                if (approvedImages.length > 0) {
                    const urisResponse = await axios.get(
                        `${MainUrl.GetSasUrlByDocumentId}?id=${id}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                            },
                        }
                    );
    
                    const uris = urisResponse.data;
    
                    if (Array.isArray(uris)) {
                        approvedImages.forEach((image) => {
                            const uriEntry = uris.find((uri) => uri.Item1 === image.documentSide);
                    
                            if (uriEntry && image.documentSide) {
                                image.uri = uriEntry.Item2;
                            } else {
                                console.warn(`No matching uri found for image.documentSide: ${image.documentSide}`);
                            }
                        });
                    } else {
                        console.error('uris is not a valid array:', uris);
                    }
                }
    
                setDocument(documentData);
            } catch (error) {
                console.error('Error fetching document data:', error.message || error);
            }
        };
        if (accessToken != null) {
            fetchDocumentData();
        }
    }, [accessToken]);

    return (
        <div>
            {document ? (
                <div>
                    <div style={{ marginTop: "0.7%", marginLeft: "1%", marginRight: "1%" }}>
                        <BackButton />
                    </div>
                    <Document document={document} />
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}

export default DetailsDocument;
