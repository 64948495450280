import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import { Tabs, Pagination, Radio, Alert, Button, Image, Spin } from 'antd';
import { CommonAdminUrl } from '../../services/ServiceEndpoints';
import './style.css';
import { createErrorElement, formatOptions } from '../../utils/Utils';
import { imageStatus } from '../../utils/Commons';
import Loading from '../Elements/Loading';
import BackButton from '../Elements/Back';
import { getOptions } from '../../services/documentService';
import FilterSelect from '../MainPage/FilterSelect';

const { TabPane } = Tabs;

export default function ModeratePanel({ accessToken, userName }) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [activeTab, setActiveTab] = useState('1');
  const [filters, setFilters] = useState({});
  const [options, setOptions] = useState({});
  const [loadingId, setLoadingId] = useState(null);

  const activeTabRef = useRef(activeTab);

  const fetchOptionsForFiltersFirstInit = async (updatedFilters) => {
    try {
      setError(null);
      const [countries, documentTypes, states, documentVersions] = await Promise.all([
        getOptions('countryCode', updatedFilters, "no", accessToken),
        getOptions('documentType', updatedFilters, "no", accessToken),
        getOptions('state', updatedFilters, "no", accessToken),
        getOptions('documentVersion', updatedFilters, "no", accessToken),
      ]);

      const documentVerRefacted = documentVersions?.map(v =>
        typeof v === 'string' ? v.replace(/,/g, "_") : v
      );

      setOptions({
        countries: countries.map(option => ({
          value: option, // Use ISO code as the value
          label: option // Display both ISO and full name
        })),
        documentTypes: formatOptions(documentTypes),
        states: formatOptions(states),
        documentVersions: formatOptions(documentVerRefacted),
      });
    } catch (error) {
      setError(createErrorElement(error, "fetchOptionsForFilters"));
    }
  };

  const fetchDocuments = useCallback(async (status, updatedFilters) => {
    setLoading(true);
    setError(null);
    try {
      fetchOptionsForFiltersFirstInit(updatedFilters);
      const serializedFilters = encodeURIComponent(JSON.stringify(updatedFilters || {}));
      const response = await axios.get(
        `${CommonAdminUrl.GetImagesByStatus}?status=${status}&pageNumber=${currentPage}&pageSize=${pageSize}&filter=${serializedFilters}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setDocuments(response.data || []);
      setTotalDocuments(Number(response.headers['x-total-count'] || 0));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (err) {
      setError(createErrorElement(err, "fetchDocuments"));
    } finally {
      setLoading(false);
    }
  }, [accessToken, currentPage, pageSize]);

  const fetchByCurrentTab = useCallback(() => {
    const tabStatusMap = {
      '1': imageStatus.Pending,
      '2': imageStatus.Approved,
      '3': imageStatus.Declined,
    };
    fetchDocuments(tabStatusMap[activeTab], filters);
  }, [activeTab, fetchDocuments]);

  useEffect(() => {
    fetchByCurrentTab();
  }, [fetchByCurrentTab, activeTab, currentPage, pageSize]);

  const handleTabChange = (key) => {
    activeTabRef.current = key;
    setActiveTab(key);
    setCurrentPage(1);
    setFilters({})
  };

  const handleStatusChange = async (id, status, imageId) => {
    try {
      setError(null);
      setLoadingId(`${id}-${imageId}`);

      // update the status locally
      const updatedDocuments = documents.map((doc) => {
        if (doc.Id === id && doc.ImageId === imageId) {
          return { ...doc, ImageStatus: parseInt(status) };
        }
        return doc;
      });
      setDocuments(updatedDocuments);

      const specimenStatusDto = {
        Id: id,
        ImageStatus: parseInt(status),
        ImageId: imageId,
        ModerateBy: userName,
      };

      // Simulate a delay
      setTimeout(async () => {
        await axios.put(CommonAdminUrl.UpdateImageStatus, specimenStatusDto, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        const filteredDocuments = updatedDocuments.filter(
          (doc) => !(doc.Id === id && doc.ImageId === imageId)
        );

        setDocuments(filteredDocuments);
        setTotalDocuments((prevTotal) => prevTotal - 1);

        if (filteredDocuments.length === 0 && totalDocuments > 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        } else if (filteredDocuments.length === 0 && totalDocuments > 0) {
          fetchByCurrentTab();
        }
        setLoadingId(null);
      }, 1000);
    } catch (error) {
      setLoadingId(null);

      // in case of error, revert status
      const revertedDocuments = documents.map((doc) => {
        if (doc.Id === id && doc.ImageId === imageId) {
          return { ...doc, ImageStatus: doc.ImageStatus };
        }
        return doc;
      });
      setDocuments(revertedDocuments);

      setError(createErrorElement(error, "handleStatusChange"));
    }
  };

  const handleBatchApproval = async () => {
    const approvedDocs = [];
    setLoading(true);
    try {
      for (const doc of documents) {
        const specimenStatusDto = {
          Id: doc.Id,
          ImageStatus: 2,
          ImageId: doc.ImageId,
          ModerateBy: userName,
        };

        await axios.put(CommonAdminUrl.UpdateImageStatus, specimenStatusDto, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        approvedDocs.push(doc);
      }

      setDocuments((prev) => prev.filter((doc) => !approvedDocs.includes(doc)));
      setTotalDocuments((prevTotal) => prevTotal - approvedDocs.length);

      if (documents.length === 0 && currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
      } else {
        fetchByCurrentTab();
      }
    } catch (error) {
      setError(createErrorElement(error, "handleBatchApproval"));
    } finally {
      setLoading(false);
    }
  };

  const displayDocuments = (docs) => docs.map((doc, index) => (
    <div key={`${doc.Id}-${index}`} className="card" style={{ minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <h3>
        {doc.Classification.CountryCode} - {doc.Classification.DocumentType}{' '}
        {doc.Classification.DocumentVersion}
        {doc.Classification.State ? ` - ${doc.Classification.State}` : ''}
        {doc.DataOrigin === 0 ? ' - Front' : doc.DataOrigin === 1 ? ' - Back' : doc.DataOrigin === 2 ? ' - Data' : ''}
      </h3>
      <p style={{ fontSize: '12px' }}>Document Id {doc.Id}</p>
      <p style={{ fontSize: '12px' }}>Image Id {doc.ImageId}</p>
      {activeTab === "1" ? null : <p style={{ fontSize: '12px', fontWeight: "bold" }}>Moderate by {doc.ModerateBy}</p>}

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {doc.Uri ? (
          <Image.PreviewGroup >
            <Image
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "fit",
              }}
              src={doc.Uri}
              alt="Document Image"
              onContextMenu={(e) => e.preventDefault()}
            />
          </Image.PreviewGroup>
        ) : (
          <p>No image available</p>
        )}
      </div>
      <p>{doc.description}</p>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '10px' }}>
        <Spin spinning={loadingId === `${doc.Id}-${doc.ImageId}`}>

          <Radio.Group
            value={doc.ImageStatus}
            onChange={(e) => handleStatusChange(doc.Id, e.target.value, doc.ImageId)}
            style={{ fontSize: '16px' }}
          >
            <Radio value={2} style={{ marginRight: '10px' }}>Approved</Radio>
            <Radio value={3}>Decline</Radio>
            <Radio value={1} style={{ marginRight: '10px' }}>Pending</Radio>
          </Radio.Group>
        </Spin>

      </div>
    </div>
  ));

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleFilterChange = async (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    fetchDocuments(activeTab == 1 ? "Pending" : (activeTab == 2 ? "Approved" : "Declined"), updatedFilters);
  };

  if (error) return <Alert message={error} type="error" showIcon style={{ margin: "15px" }} />;

  return (
    <div style={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      <BackButton />
      <div
        style={{
          textAlign: 'left',
          paddingLeft: '20px',
          paddingBottom: '20px',
          paddingTop: '10px',
          borderBottom: '2px solid #e0e0e0',
        }}
      >
        <h4 style={{ fontSize: '24px', color: '#4a90e2', margin: 0 }}>Review and Approve Images</h4>
        <p style={{ fontSize: '14px', color: '#757575', marginTop: '10px' }}>
          Use the tabs below to review, approve, or decline images.
        </p>
      </div>

      {/* Filters */}
      <div style={{ display: 'flex', width: '100%', padding: '16px', gap: '16px' }}>
        <div style={{ flex: 1 }}>
          <FilterSelect
            options={options.countries}
            placeholder="Select Country"
            value={filters.countryCode}
            onChange={(val) => handleFilterChange('countryCode', val)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <FilterSelect
            options={options.documentTypes}
            placeholder="Select Doc Type"
            value={filters.documentType}
            onChange={(val) => handleFilterChange('documentType', val)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <FilterSelect
            options={options.states}
            placeholder="Select State"
            value={filters.state}
            onChange={(val) => handleFilterChange('state', val)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <FilterSelect
            options={options.documentVersions}
            placeholder="Select Doc Version"
            value={filters.documentVersion}
            onChange={(val) => handleFilterChange('documentVersion', val)}
          />
        </div>
      </div>

      {/* Tabs and Content */}
      <div style={{ flex: 1, overflowY: 'auto', minHeight: "1200px", padding: '16px' }}>
        <Tabs defaultActiveKey="1" value={activeTab} onChange={handleTabChange}>
          <TabPane tab="Pending" key="1">
            {documents && documents.length === 0 && !loading && 'No data'}
            {loading ? (
              <Loading textAlign="left" />
            ) : (
              <>
                <div className="grid-container">{displayDocuments(documents)}</div>
                {activeTab === '1' && (
                  <div style={{ marginBottom: '10px' }}>
                    <Spin spinning={loading}>
                      <Button
                        type="primary"
                        style={{ margin: '18px', fontSize: '16px' }}
                        onClick={handleBatchApproval}
                      >
                        Batch Approval (10 docs)
                      </Button>
                      {loading ? 'Approving...' : null}
                    </Spin>
                  </div>
                )}
              </>
            )}
          </TabPane>
          <TabPane tab="Approved" key="2">
            {loading ? (
              <Loading textAlign="left" />
            ) : (
              <div className="grid-container">{displayDocuments(documents)}</div>
            )}
            {documents && documents.length === 0 && !loading && 'No data'}
          </TabPane>
          <TabPane tab="Declined" key="3">
            {loading ? (
              <Loading textAlign="left" />
            ) : (
              <div className="grid-container">{displayDocuments(documents)}</div>
            )}
            {documents && documents.length === 0 && !loading && 'No data'}
          </TabPane>
        </Tabs>
      </div>

      {/* Pagination */}
      <div style={{
        position: 'sticky',
        bottom: 0,
        zIndex: 100,
        background: '#fff',
        borderTop: '1px solid #ddd',
        padding: '16px',
        textAlign: 'right',
      }}>
        {activeTab !== '4' && (
          <Pagination
            current={currentPage}
            total={totalDocuments}
            pageSize={pageSize}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            showSizeChanger
            pageSizeOptions={['10']}
          />
        )}
      </div>
    </div>
  );
}  