import React from "react";

export const DataExtractionList = (props) => {
  const { item, uniqueKey } = props;

  return (
    <li key={uniqueKey}>
      {item.fieldSource} :: {item.name}
    </li>
  );
};

export default DataExtractionList;
