import React from "react";
import { Carousel, Image } from "antd";
import "./CarouselImage.css";
import imageNotFound from "../../assets/noImgFound.jpg";

export const CarouselImages = ({ header, image }) => {
  const imageWidth = window.innerWidth < 768 ? "300px" : "450px";

  return (
    <div className="carousel-container">
      <h5 className="carousel-header">{header}</h5>
      <div className="carousel-content" style={{ textAlign: "center" }}>
        {image?.length > 0 && image[0] != null ? (
          <Image.PreviewGroup>
            <Carousel autoplay effect="fade" dots={false}>
              {image.map((url, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <Image
                    style={{ width: imageWidth, maxHeight: "400px", 
                    objectFit: "contain"}} 
                    onContextMenu={(e) => e.preventDefault()}
                    src={url}
                    alt="image not found"
                    fallback={imageNotFound}
                    loading="lazy"
                    onError={(e) => {
                      e.target.style.width = "150px";
                      e.target.style.height = "auto";
                    }}
                  />

                </div>
              ))}
            </Carousel>
          </Image.PreviewGroup>
        ) : (
          <div>
            <Image
              alt="image not found"
              style={{  width: imageWidth, maxHeight: "400px", 
                objectFit: "contain" }}
              onContextMenu={(e) => e.preventDefault()}
              src={imageNotFound}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CarouselImages;


