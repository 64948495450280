import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SecurityComponent from "./Login/SecurityComponent";
import { ReloadProvider } from "./utils/ReloadProvider";

const App = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      if (event.target.tagName === "IMG") {
        event.preventDefault();
      }
    };
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <Router>
        <ReloadProvider>
          <SecurityComponent />
        </ReloadProvider>
    </Router>
  );
};

export default App;
