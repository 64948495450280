import React, { useState, useRef, useMemo, useEffect } from "react";
import Au10tixLogo from "../../assets/icons/Au10tixLogo.svg";
import { useOktaAuth } from "@okta/okta-react";
import { Button, message } from "antd";
import { CSVLink } from "react-csv";
import "./CustomHeader.css";
import { MainUrl } from "../../services/ServiceEndpoints.jsx";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import { useReload } from "../../utils/ReloadProvider.jsx";
import { useUserContext } from "../../utils/UserProvider.jsx";
import { userGroupsPostFix } from "../../utils/Commons.jsx";

const CustomHeader = ({ accessToken }) => {
  const { oktaAuth } = useOktaAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInternalUser, setIsInternalUser] = useState([]);

  const csvLinkRef = useRef(null);
  const navigate = useNavigate();
  const { triggerReload } = useReload();
  const { userGroups } = useUserContext();

  useEffect(() => {
    const isInternalUser = userGroups?.every(group => !group.includes(userGroupsPostFix.Viewer));
    setIsInternalUser(isInternalUser);
  }, [userGroups]);

  const location = useLocation();

  const isMainPage = !["moderatePanel", "detail", "audit"].some((path) =>
    location.pathname.includes(path)
  );

  const memoizedData = useMemo(() => data, [data]);

  useEffect(() => {
    // Automatically trigger the CSV download when data is updated
    if (data.length > 0 && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  }, [data]);

  const fetchExportData = async () => {
    if (memoizedData.length > 0) {
      message.success("CSV is downloaded.");
      if (csvLinkRef.current) {
        csvLinkRef.current.link.click(); // Trigger CSV download
      }
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.get(MainUrl.GetDocumentsDataForCsv, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const fetchedData = response.data || [];
      setData(fetchedData);
      message.success("CSV is downloaded.");
    } catch (err) {
      message.error("Error fetching data for export.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    await oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin,
    });
  };

  return (
    <Header className="custom-header">
      <div className="flex-header-container">
        <div
          className="header-wrapper"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/');
            triggerReload();
          }}
        >
          <img
            width="auto"
            marginLeft="10px"
            height="25px"
            alt="logo"
            src={Au10tixLogo}
          />
          <h1 className="page-header">DOCU</h1>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
        {/* !!! Group with postfix "Viewer" not allowed to see export !!! */ }
          {isMainPage && isInternalUser && (
            <div>
              <Button
                className="export-btn"
                onClick={fetchExportData}
                loading={isLoading}
                style={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Export Docs (.csv)
              </Button>
              <CSVLink
                data={memoizedData}
                filename="Supported_docs.csv"
                className="hidden-csv-link"
                ref={csvLinkRef}
              />
            </div>
          )}
          <Button style={{ fontWeight: 700 }} onClick={handleLogout}>
            Log out
          </Button>
        </div>
      </div>
    </Header>
  );
};

export default CustomHeader;
