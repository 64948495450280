import React from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom"; // Updated for React Router v6
import { Security, LoginCallback, useOktaAuth } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
import { Login } from "./Login";
import Loading from '../components/Elements/Loading';
import AdminComponent from "../components/AdminConsole/AdminComponent";
import CustomHeader from "../components/Header/CustomHeader";
import MainPage from "../components/MainPage/MainPage";
import "./style.css";
import Audit from "../components/AdminConsole/Audit";
import { UserProvider } from "../utils/UserProvider";

const oktaAuth = new OktaAuth({
  issuer: "https://login.au10tix.com/oauth2/default",
  clientId: "0oafn0qce5SENsbif357",
  redirectUri: "https://docupedia.au10tixservicesdev.com/login/callback",
  pkce: true,
  scopes: ['openid', 'profile', 'email'],
  tokenManager: {
    storage: "localStorage",
  },
});

const SecureRoute = ({ element: Element, ...rest }) => {
  const { authState } = useOktaAuth();

  if (!authState || authState.isPending) {
    return null;
  }
  const idToken = authState?.accessToken?.accessToken;

  return authState.isAuthenticated ? (
    <UserProvider><Element accessToken={idToken} {...rest} /></UserProvider>
  ) : (
    <Navigate to="/login" />
  );
};

const SecurityComponent = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const relativeUrl = toRelativeUrl(originalUri || "/", window.location.origin);
    navigate(relativeUrl);
  };

  return (
    <div>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <SecureRoute element={CustomHeader} />
        <Routes>
          <Route path="/login/callback" element={<LoginCallback loadingElement={<Loading />} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<SecureRoute element={MainPage} />} />
          <Route path="/detail" element={<SecureRoute element={DetailsDocument} />} />
          <Route
            path="/moderatePanel"
            element={
              <SecureRoute element={AdminComponent} />
            }
          />
          <Route
            path="/moderatePanel/audit"
            element={
              <SecureRoute element={Audit} />
            }
          />
        </Routes>
      </Security>
    </div>
  );
};
export default SecurityComponent;